/* LastSeenFloating Style Sheet */
.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.Blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes ripple {
    from {
        transform: "scale(.8)";
        opacity: 1;
    }

    to {
        transform: "scale(1.2)";
        opacity: 0;
    }
}

